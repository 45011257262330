.add-list {

  &__form {
    label {
      display:block;
      margin-bottom:0.5rem;
    }

    &__label {
      display:block;
      margin-bottom:0.5rem;
      font-size:14px;
    }
  }
}
.dashboard-header {
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  .page-header {
    flex-grow: 1;
  }
}

.hero-cards, .cards {
  margin: -6px;
  margin-bottom: 14px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  & > * {
    margin: 6px;
    flex-grow: 1;
    flex-basis: 250px;
  }
}

.sections {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;

  .section {
    flex: 1 0 calc(50% - 10px);
    
    &:nth-child(2n + 1):not(:last-child) {
      margin-right: 20px;
    }
    // flex-basis: 50%;

    .sub-header {
      margin-bottom: 20px;
    }
  }
}


.delete-button {
  background-color: #F04533 !important;
}
.fixture-list {
  padding-top:1rem;
  display:flex;
  flex-wrap:wrap;
  gap:1rem;



  &__item {
    width:500px;
    gap:1rem;

    .fixture-image {
      width:150px;
      display:flex;
      flex-direction:column;
      gap:1rem;

      img {
        max-width:100%;
        height:130px;
        object-fit:contain;
        display:block;
        margin:auto;
      }
  

    }
    .fixture-list__item__details {
      display:flex;
      flex-direction:column;
      gap:1rem;
      flex-grow:1;
    }

    .input { 
      width: 100%;
    }

  }
}